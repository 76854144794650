import React, { useEffect } from "react"
//import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from "echarts/core"
import { GridComponent } from "echarts/components"
import { useDispatch, useSelector } from "react-redux"
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import { LabelLayout, UniversalTransition } from "echarts/features"
import ReactEChartsCore from "echarts-for-react"
import { getSubmissions } from "store/firestore/submissions/slice"
echarts.use([GridComponent, LineChart, CanvasRenderer, UniversalTransition])

const PositivityGraph = props => {
  const assessment = "gratitude_positivity"
  const dispatch = useDispatch()
  var currentDate = new Date()
  var sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)
  sevenDaysAgo.setHours(0, 0, 0, 0)
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)

  const daysCount = 7
  const user = JSON.parse(localStorage.getItem("authUser"))
  var user_id = user.email
  useEffect(() => {
    dispatch(
      getSubmissions({
        emailId: user_id,
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
        assessmentId: assessment,
      })
    )
  }, [dispatch])

  const submissions = useSelector(state => {
    return state.Submissions.SubmissionsMap
  })

  const getDatesArray = days => {
    const arr = []
    const end = new Date()
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(end)
      date.setDate(end.getDate() - i)
      arr.push(date.toLocaleDateString())
    }
    return arr
  }
  const xData = []
  const getMetricData = (categories, metric, submissions, noOfDays) => {
    if (!Array.isArray(submissions[assessment])) {
      return []
    }
    const dates = getDatesArray(noOfDays)

    var data = submissions[assessment]
    const groupedData = {}
    for (const item of data) {
      const dateObj = new Date(item.date)
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      if (!groupedData[dateObj]) {
        groupedData[dateObj] = 0
        xData.push(formattedDate)
      }
      groupedData[dateObj] += item.responses.submission.positivity.q25
    }
    return groupedData
  }

  const xAxisData = []
  //const emoDays = []
  const legendData = []
  //const dayEmos = []
  legendData.push("trend")
  const encodeY = []
  for (var i = daysCount - 1; i >= 0; i--) {
    legendData.push(i == 0 ? "D" : "D-" + i)
    //dayEmos.push([])
    encodeY.push(i)
  }

  const data = getMetricData(xAxisData, "frequency", submissions, 7)

  const values = []
  for (const [date, totalPositivity] of Object.entries(data)) {
    //xData.push(date)
    values.push(totalPositivity)
  }
  const emoDays = data ? data : []
  if (data && legendData) {
    const options = {
      xAxis: {
        type: "category",
        data: xData,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: values,
          type: "line",
        },
      ],
    }

    return <ReactEChartsCore echarts={echarts} option={options} />
  } else {
    return <div>Loading....</div>
  }
}
export default PositivityGraph
