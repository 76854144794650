import React from 'react'
import Section from './Section'
import Section1 from './Section1'
import Navbar8 from '../../layout/Navbar8'
import { Container, Row, Col } from "react-bootstrap"

const Index9 = () => {
  return (
    <React.Fragment>        
      <div>
        <Container fluid>
          <Section />
          {/* <Section1 /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index9