import React, { useState } from 'react';
import Section from './Section';
import Navbar8 from '../../layout/Navbar8';
import { Container, Modal } from 'react-bootstrap';
import Login from 'pages/Authentication/Login';

const Index = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid style={{ padding: '0px' }}>
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="d-flex justify-content-center">
                  <Login />
                </div>
              </Modal.Title>
            </Modal.Header>
          </Modal>
          <Navbar8 openModel={openModal} />
          <Section />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;