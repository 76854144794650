import React, { useEffect } from "react"
//import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from "echarts/core"
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components"
import { useDispatch, useSelector } from "react-redux"
import { LineChart } from "echarts/charts"
import { LabelLayout, UniversalTransition } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"
import ReactEChartsCore from "echarts-for-react"
import { getEMCSubmissions } from "store/firestore/submissions/slice"
import raw_data from "./chart_data2"
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  LabelLayout,
  UniversalTransition,
])

const Intensity = props => {
  const datasetWithFilters = []
  const seriesList = []
  const submissions = useSelector(state => {
    return state.Submissions.Submissions
  })
  const categories = useSelector(state => {
    return state.Questions.assessments["emotional_check_in"]?.categories
  })
  const emotions = categories
  const getMetricTimeArray = (metric, categories, submissions, noOfDays) => {
    const rows = [[metric, "Emotion", "Day"]]
    const dates = getDates(noOfDays)
    dates.forEach(date => {
      categories?.forEach(emotion => {
        const submission = submissions.find(sub => {
          const submissionDate = new Date(sub.date)

          const submissionDay = new Date(
            submissionDate.getFullYear(),
            submissionDate.getMonth(),
            submissionDate.getDate()
          )

          const dateObj = new Date(date)
          const day = new Date(
            dateObj.getFullYear(),
            dateObj.getMonth(),
            dateObj.getDate()
          )

          return day.getTime() === submissionDay.getTime()
        })
        const value = submission?.responses?.chart?.[emotion]?.[metric] ?? 0
        rows.push([value, emotion, getDaysAgo(date)])
      })
    })

    return rows
  }

  const getDates = days => {
    const dates = []
    const today = new Date()
    for (let i = 0; i < days; i++) {
      dates.unshift(formatDate(new Date(today).setDate(today.getDate() - i)))
    }
    return dates
  }

  const formatDate = date => {
    const d = new Date(date)
    return d.toISOString().slice(0, 10)
  }

  const getDaysAgo = isoString => {
    const submissionDate = new Date(isoString)
    const today = new Date().setHours(0, 0, 0, 0)
    const diff = Math.ceil((today - submissionDate) / (1000 * 3600 * 24))
    return diff == 0 ? "D" : "D-" + diff
  }

  const data = getMetricTimeArray("intensity", categories, submissions, 7)

  echarts.util.each(emotions, function (emotion) {
    var datasetId = "dataset_" + emotion
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: "dataset_raw",
      transform: {
        type: "filter",
        config: {
          and: [{ dimension: "Emotion", "=": emotion }],
        },
      },
    })
    seriesList.push({
      type: "line",
      datasetId: datasetId,
      showSymbol: false,
      name: emotion,
      endLabel: {
        show: true,
        formatter: function (params) {
          return params.value[1]
        },
      },
      labelLayout: {
        moveOverlap: "shiftY",
      },
      emphasis: {
        focus: "series",
      },
      encode: {
        x: "Day",
        y: "Intensity",
        label: ["Intensity"],
        itemName: "Day",
        tooltip: ["Intensity"],
      },
    })
  })

  const dispatch = useDispatch()
  var currentDate = new Date()
  var sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)
  sevenDaysAgo.setHours(0, 0, 0, 0)
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)
  const user = JSON.parse(localStorage.getItem("authUser"))
  var user_id = user.email
  useEffect(() => {
    dispatch(
      getEMCSubmissions({
        emailId: user_id,
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
      })
    )
  }, [dispatch])

  const options = {
    animationDuration: 10000,
    dataset: [
      {
        id: "dataset_raw",
        source: data,
      },
      ...datasetWithFilters,
    ],
    tooltip: {
      order: "valueDesc",
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      nameLocation: "middle",
    },
    yAxis: {
      name: "Intensity",
    },
    grid: {
      right: 140,
    },
    series: seriesList,
  }
  return <ReactEChartsCore echarts={echarts} option={options} />
}
export default Intensity
