import { takeEvery, all, fork, call, put, select } from "redux-saga/effects"
import { db } from "App"
import { collection, getDocs, setDoc, doc } from "firebase/firestore"
import { storeQuestions, sumissionFailed, sumissionSuccess } from "./slice"
function* getQuestions({ payload }) {
  var loadFromDB = true
  if (payload.checkCache) {
    loadFromDB = false
    const ques = yield select(state => state.Questions.assessments)
    if (!Object.keys(ques) || Object.keys(ques).length === 0) {
      loadFromDB = true
    }
  }
  if (loadFromDB) {
    const questionsSnapshot = yield call(getDocs, collection(db, "questions"))
    const categoriesSnapshot = yield call(getDocs, collection(db, "categories"))
    const assessmentsSnapshot = yield call(
      getDocs,
      collection(db, "assessments")
    )
    const categories = {}
    questionsSnapshot.forEach(doc => {
      doc.data().categories.forEach(cat => {
        for (var categoryKey in cat) {
          if (!categories[categoryKey]) {
            categories[categoryKey] = {}
          }
          categories[categoryKey][doc.data().id] = {
            ...doc.data(),
          }
        }
      })
    })

    const assessments = {}
    const allCategories = []
    categoriesSnapshot.forEach(doc => {
      allCategories.push(...doc.data().labels)
    })
    assessmentsSnapshot.forEach(doc => {
      var categoryKeys = []
      doc.data().categories.forEach(cat => {
        categoryKeys.push(Object.keys(cat)[0])
      })
      assessments[doc.id] = {
        categories: categoryKeys,
        label: doc.data().label,
        questions: {},
      }
      doc.data().categories.forEach(cat => {
        var key = Object.keys(cat)[0].toLowerCase()
        assessments[doc.id]["questions"][Object.keys(cat)[0]] = categories[key]
      })
    })
    yield put(
      storeQuestions({
        assessments: assessments,
        categories: allCategories,
      })
    )
  }
}
function* postQuestion() {
  yield call(setDoc, doc(collection(db, "questions")), {
    id: "q40",
    question: "How intense was your contentment today?",
    metric: ["intensity"],
    options: [
      {
        label: "Mild",
        value: 3,
      },
      {
        label: "Moderate",
        value: 6,
      },
      {
        label: "Strong",
        value: 9,
      },
      {
        label: "Very Strong",
        value: 10,
      },
    ],
    type: "WEIGHTED",
    categories: [{ contentment: 2 }],
  })
}
function* postSubmission({ payload: { submission } }) {
  try {
    yield call(setDoc, doc(collection(db, "submissions")), submission)
    yield put(sumissionSuccess("Submission Successfull"))
  } catch (error) {
    console.log(error)
    yield put(sumissionFailed("Error in recording your response"))
  }
}
function* QuestionsSaga() {
  yield takeEvery("QuestionsSlice/getQuestions", getQuestions)
  yield takeEvery("QuestionsSlice/postQuestion", postQuestion)
  yield takeEvery("QuestionsSlice/postSubmission", postSubmission)
}
export default QuestionsSaga
