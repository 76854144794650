import { takeEvery, all, fork, call, put, select } from "redux-saga/effects"
import { db } from "App"
import {
  doc,
  collection,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore"
import { storeUserSettings } from "./slice"
function* getUserSettingsAPI({ payload }) {
  const emailId = payload.emailId

  const userConfigRef = collection(db, "user_config")
  const userConfigQuery = query(userConfigRef, where("user_id", "==", emailId))

  const userConfig = yield call(getDocs, userConfigQuery)
  const userSettings = {}
  if (userConfig.size > 0) {
    userConfig.forEach(doc => {
      userSettings["docId"] = doc.id
      userSettings["settings"] = doc.data()
    })
    yield put(storeUserSettings(userSettings))
  } else {
    const newUserSettings = {
      opted_values: [],
      submissions: {},
      user_id: emailId,
    }
    setDoc(doc(userConfigRef), newUserSettings)
    const userConfig = yield call(getDocs, userConfigQuery)
    userConfig.forEach(doc => {
      userSettings["docId"] = doc.id
      userSettings["settings"] = doc.data()
    })
    // const docRef = yield call(setDoc, doc(userConfigRef), newUserSettings)
    // const userSettings = {
    //   docId: docRef.id,
    //   settings: newUserSettings,
    // }
    yield put(storeUserSettings(userSettings))
  }
}
function* updateUserSettingsAPI({ payload }) {
  try {
    const emailId = payload.emailId
    const opted_values = payload.opted_values
    const userConfigRef = doc(db, "user_config", payload.docId)
    const settings = {
      opted_values: opted_values,
    }
    yield call(updateDoc, userConfigRef, settings)
  } catch (error) {
    console.log(error)
  }
}
// function* updateLsd({ payload }) {
//   try {
//     const emailId = payload.emailId
//     const assessment_id = payload.assessment_id
//     const userConfigRef = doc(db, "user_config", payload.docId)
//     var suffix = "_lsd"
//     const settings = {
//       [`${assessment_id}${suffix}`]: payload.submission_date,
//     }
//     yield call(updateDoc, userConfigRef, settings)
//   } catch (error) {
//     console.log(error)
//   }
// }
function* updateLsd({ payload }) {
  try {
    const emailId = payload.emailId
    const assessment_id = payload.assessmentId
    const userConfigRef = doc(db, "user_config", payload.docId)
    const userConfigSnapshot = yield call(getDoc, userConfigRef)
    const userConfigData = userConfigSnapshot.data()

    const suffix = "_lsd"
    const submissionKey = `${assessment_id}${suffix}`
    const submissionDate = payload.submissionDate

    if (userConfigData && userConfigData.submissions) {
      const updatedSubmissions = {
        ...userConfigData.submissions,
        [submissionKey]: submissionDate,
      }

      const settings = { submissions: updatedSubmissions }
      yield call(updateDoc, userConfigRef, settings)
      const userSettings = {
        docId: payload.docId,
        settings: {
          ...userConfigData,
          submissions: updatedSubmissions,
        },
      }
      yield put(storeUserSettings(userSettings))
    } else {
      const settings = {
        submissions: { [submissionKey]: submissionDate },
      }
      yield call(updateDoc, userConfigRef, settings)
      const userSettings = {
        docId: payload.docId,
        settings: {
          ...userConfigData,
          submissions: settings.submissions,
        },
      }
      yield put(storeUserSettings(userSettings))
    }
  } catch (error) {
    console.log(error)
  }
}
function* SettingsSaga() {
  yield takeEvery("SettingsSlice/getUserSettings", getUserSettingsAPI)
  yield takeEvery("SettingsSlice/updateUserSettings", updateUserSettingsAPI)
  yield takeEvery("SettingsSlice/updateLsd", updateLsd)
}
export default SettingsSaga
