import { createSlice } from "@reduxjs/toolkit"
const initialState = {
  Submissions: [],
  SubmissionsMap: {},
}
export const SubmissionsSlice = createSlice({
  name: "SubmissionsSlice",
  initialState: initialState,
  reducers: {
    getEMCSubmissions: (currentState, action) => {},
    getSubmissions: (currentState, action) => {},
    storeEMCSubmissions: (currentState, action) => {
      currentState.Submissions = action.payload.Submissions
    },
    storeSubmissions: (currentState, action) => {
      currentState.SubmissionsMap[action.payload.assessmentId] =
        action.payload.Submissions
    },
  },
})
export default SubmissionsSlice.reducer
export const {
  getEMCSubmissions,
  getSubmissions,
  storeEMCSubmissions,
  storeSubmissions,
} = SubmissionsSlice.actions
