import { createSlice } from "@reduxjs/toolkit"
const initialState = {
  Settings: [],
}
export const SettingsSlice = createSlice({
  name: "SettingsSlice",
  initialState: initialState,
  reducers: {
    getUserSettings: (currentState, action) => {},
    storeUserSettings: (currentState, action) => {
      currentState.Settings = action.payload
    },
    updateUserSettings: (currentState, action) => {},
    updateLsd: (currentState, action) => {},
  },
})
export default SettingsSlice.reducer
export const {
  getUserSettings,
  storeUserSettings,
  updateUserSettings,
  updateLsd,
} = SettingsSlice.actions
