import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useDispatch } from "react-redux"
import { getQuestions, postQuestion } from "store/firestore/questions/slice"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
const QuestionBank = () => {
  document.title = "Config | Questions"

  const dispatch = useDispatch()
  const postClick = event => {
    event.preventDefault()
    dispatch(postQuestion())
  }

  useEffect(() => {
    dispatch(getQuestions({ checkCache: true }))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Config" breadcrumbItem="Questions" />
          <form>
            <button onClick={postClick}>Post Question</button>
          </form>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default QuestionBank
