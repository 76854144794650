import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { forgetPasswordSuccess, forgetPasswordError } from "./slice"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postJwtForgetPwd } from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUserAPI({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.forgetPassword, user.email)
      if (response) {
        yield put(
          forgetPasswordSuccess(
            "Reset link are sent to your mailbox, kinldy check there"
          )
        )
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
        email: user.email,
      })
      if (response) {
        yield put(
          forgetPasswordSuccess(
            "Reset link are sent to your mailbox, kindly check there"
          )
        )
      }
    }
  } catch (error) {
    yield put(forgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery("forgetPwdSlice/forgetPassword", forgetUserAPI)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
