import React, { useEffect } from "react"
//import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from "echarts/core"
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components"
import { useDispatch, useSelector } from "react-redux"
import { CanvasRenderer } from "echarts/renderers"
import { BarChart } from "echarts/charts"
import { LabelLayout, UniversalTransition } from "echarts/features"
import ReactEChartsCore from "echarts-for-react"
import { getSubmissions } from "store/firestore/submissions/slice"
echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
])

const GratitudeBars = props => {
  const assessment = "gratitude_positivity"
  const dispatch = useDispatch()
  var currentDate = new Date()
  var sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)
  sevenDaysAgo.setHours(0, 0, 0, 0)
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)

  const daysCount = 7
  const user = JSON.parse(localStorage.getItem("authUser"))
  var user_id = user.email
  useEffect(() => {
    dispatch(
      getSubmissions({
        emailId: user_id,
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
        assessmentId: assessment,
      })
    )
  }, [dispatch])

  const submissions = useSelector(state => {
    return state.Submissions.SubmissionsMap
  })

  const getDatesArray = days => {
    const arr = []
    const end = new Date()
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(end)
      date.setDate(end.getDate() - i)
      arr.push(date.toLocaleDateString())
    }
    return arr
  }

  const getMetricData = (categories, metric, submissions, noOfDays) => {
    if (!Array.isArray(submissions[assessment])) {
      console.log(submissions[assessment])
      return []
    }
    const dates = getDatesArray(noOfDays)

    var data = submissions[assessment]
    const groupedData = {}
    for (const item of data) {
      const dateObj = new Date(item.date)
      const formattedDate = dateObj.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      if (!groupedData[formattedDate]) {
        groupedData[formattedDate] = 0
      }
      groupedData[formattedDate] += item.responses.submission.positivity.q25
    }
    return groupedData
  }

  function countGratitudeOccurrences(submissions) {
    if (!Array.isArray(submissions[assessment])) {
      return []
    }
    const gratitudeCounts = {}
    var data = submissions[assessment]
    for (const item of data) {
      const gratitudes = item.responses.submission.gratitude.q26

      for (const gratitude of gratitudes) {
        gratitudeCounts[gratitude] = (gratitudeCounts[gratitude] || 0) + 1
      }
    }

    return gratitudeCounts
  }

  const ques = useSelector(state => {
    return state.Questions.assessments[assessment]
  })
  const gratitudeCounts = countGratitudeOccurrences(submissions)
  const causes = ques.questions.gratitude.q26.options
  const causesMap = {}
  const legends = []
  const seriesOptions = []
  const bars = []
  var app = {}
  const posList = [
    "left",
    "right",
    "top",
    "bottom",
    "inside",
    "insideTop",
    "insideLeft",
    "insideRight",
    "insideBottom",
    "insideTopLeft",
    "insideTopRight",
    "insideBottomLeft",
    "insideBottomRight",
  ]
  app.configParameters = {
    rotate: {
      min: -90,
      max: 90,
    },
    align: {
      options: {
        left: "left",
        center: "center",
        right: "right",
      },
    },
    verticalAlign: {
      options: {
        top: "top",
        middle: "middle",
        bottom: "bottom",
      },
    },
    position: {
      options: posList.reduce(function (map, pos) {
        map[pos] = pos
        return map
      }, {}),
    },
    distance: {
      min: 0,
      max: 100,
    },
  }

  app.config = {
    rotate: 90,
    align: "left",
    verticalAlign: "middle",
    position: "insideBottom",
    distance: 15,
    onChange: function () {
      const labelOption = {
        rotate: app.config.rotate,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        position: app.config.position,
        distance: app.config.distance,
      }
      myChart.setOption({
        series: seriesOptions,
      })
    },
  }
  const labelOption = {
    show: true,
    position: app.config.position,
    distance: app.config.distance,
    align: app.config.align,
    verticalAlign: app.config.verticalAlign,
    rotate: app.config.rotate,
    formatter: "{name|{a}}",
    fontSize: 16,
    rich: {
      name: {},
    },
  }
  if (causes != null) {
    for (var i in causes) {
      causesMap[causes[i].value] = causes[i].id
      legends.push(causes[i].id)
      seriesOptions.push({
        label: labelOption,
      })
      bars.push({
        name: causes[i].id,
        type: "bar",
        barGap: 0,
        label: labelOption,
        emphasis: {
          focus: "series",
        },
        data: [gratitudeCounts[causes[i].value]],
      })
    }
  }
  if (gratitudeCounts) {
    const options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: [],
      },
      toolbox: {
        show: false,
        orient: "vertical",
        left: "right",
        top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: true },
          data: ["Gratitude"],
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: bars,
    }

    return <ReactEChartsCore echarts={echarts} option={options} />
  } else {
    return <div>Loading....</div>
  }
}
export default GratitudeBars
