import React, { useEffect } from "react"
//import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from "echarts/core"
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components"
import { useDispatch, useSelector } from "react-redux"
import { CustomChart, BarChart } from "echarts/charts"
import { CanvasRenderer } from "echarts/renderers"
import ReactEChartsCore from "echarts-for-react"
import { getEMCSubmissions } from "store/firestore/submissions/slice"
import { getFirebaseBackend } from "helpers/firebase_helper"
echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  CustomChart,
  BarChart,
  CanvasRenderer,
])

const Graph = props => {
  const submissions = useSelector(state => {
    return state.Submissions.Submissions
  })
  const categories = useSelector(state => {
    return state.Questions.assessments["emotional_check_in"]?.categories
  })
  const getDatesArray = days => {
    const arr = []
    const end = new Date()
    for (let i = days - 1; i >= 0; i--) {
      const date = new Date(end)
      date.setDate(end.getDate() - i)
      arr.push(date.toLocaleDateString())
    }
    return arr
  }
  const getMetricData = (categories, metric, submissions, noOfDays) => {
    const dates = getDatesArray(noOfDays)
    const rows = categories?.map((cat, index) => {
      const cells = dates?.map(date => {
        const submission = submissions.find(
          sub => new Date(sub.date).toLocaleDateString() === date
        )
        return submission?.responses.chart[cat]?.[metric] || 0
      })
      return [index, ...cells]
    })
    return rows
  }
  const transpose = arr => {
    if (!arr) return
    const slicedArr = arr.map(row => row.slice(1))
    const transposed = []
    const cols = Math.max(...slicedArr.map(row => row.length))
    for (let i = 0; i < cols; i++) {
      transposed.push(slicedArr.map(row => row[i] ?? 0))
    }
    return transposed
  }
  const dispatch = useDispatch()
  var currentDate = new Date()
  var sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)
  sevenDaysAgo.setHours(0, 0, 0, 0)
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)
  const fireBaseBackend = getFirebaseBackend()
  useEffect(() => {
    dispatch(
      getEMCSubmissions({
        emailId: "codehut.in@gmail.com",
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
      })
    )
  }, [dispatch])

  const daysCount = 7
  const emoCount = categories?.length
  const xAxisData = categories
  //const emoDays = []
  const legendData = []
  //const dayEmos = []
  legendData.push("trend")
  const encodeY = []
  for (var i = daysCount - 1; i >= 0; i--) {
    legendData.push(i == 0 ? "D" : "D-" + i)
    //dayEmos.push([])
    encodeY.push(i)
  }

  const data = getMetricData(xAxisData, "frequency", submissions, 7)
  const tData = transpose(data)

  const dayEmos = tData ? tData : []
  const emoDays = data ? data : []
  if (data && tData && legendData && categories) {
    const options = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: legendData,
      },
      xAxis: {
        data: xAxisData,
      },
      yAxis: {},
      series: [
        {
          type: "custom",
          name: "trend",
          renderItem: function (params, api) {
            var xValue = api.value(0)
            var currentSeriesIndices = api.currentSeriesIndices()
            var barLayout = api.barLayout({
              barGap: "30%",
              barCategoryGap: "20%",
              count: currentSeriesIndices.length - 1,
            })
            var points = []
            for (var i = 0; i < currentSeriesIndices.length; i++) {
              var seriesIndex = currentSeriesIndices[i]
              if (seriesIndex !== params.seriesIndex) {
                var point = api.coord([xValue, api.value(seriesIndex)])
                if (!point) continue
                point[0] += barLayout[i - 1]?.offsetCenter
                point[1] -= 20
                points.push(point)
              }
            }
            var style = api.style({
              stroke: api.visual("color"),
              fill: "none",
            })
            return {
              type: "polyline",
              shape: {
                points: points,
              },
              style: style,
            }
          },
          itemStyle: {
            borderWidth: 2,
          },
          encode: {
            x: 0,
            y: encodeY,
          },
          data: emoDays,
          z: 100,
        },
        ...dayEmos.map(function (data, index) {
          return {
            type: "bar",
            animation: true,
            animationDuration: 5000,
            name: legendData[index + 1],
            itemStyle: {
              opacity: 0.5,
            },
            data: data,
          }
        }),
      ],
    }

    return <ReactEChartsCore echarts={echarts} option={options} />
  } else {
    return <div>Loading....</div>
  }
}
export default Graph
