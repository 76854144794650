import { takeEvery, all, fork, call, put, select } from "redux-saga/effects"
import { db } from "App"
import { collection, getDocs, query, where } from "firebase/firestore"
import { storeEMCSubmissions, storeSubmissions } from "./slice"
function* getEMCSubmissionsAPI({ payload }) {
  const emailId = payload.emailId
  const startDate = payload.startDate
  const endDate = payload.endDate
  const submissionsRef = collection(db, "submissions")
  const emotionalCheckInQuery = query(
    submissionsRef,
    where("assessment_id", "==", "emotional_check_in"),
    where("user_id", "==", emailId),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  )

  const emotionalCheckInData = yield call(getDocs, emotionalCheckInQuery)
  const submissions = []
  emotionalCheckInData.forEach(doc => {
    submissions.push(doc.data())
  })
  yield put(
    storeEMCSubmissions({
      Submissions: submissions,
    })
  )
}

function* getSubmissionsAPI({ payload }) {
  const emailId = payload.emailId
  const startDate = payload.startDate
  const endDate = payload.endDate
  const assessmentId = payload.assessmentId
  const submissionsRef = collection(db, "submissions")
  const submissionsQuery = query(
    submissionsRef,
    where(
      "assessment_id",
      "==",
      assessmentId ? assessmentId : "emotional_check_in"
    ),
    where("user_id", "==", emailId),
    where("date", ">=", startDate),
    where("date", "<=", endDate)
  )

  const submissionsQueryData = yield call(getDocs, submissionsQuery)
  const submissions = []
  submissionsQueryData.forEach(doc => {
    submissions.push(doc.data())
  })
  yield put(
    storeSubmissions({
      assessmentId: assessmentId,
      Submissions: submissions,
    })
  )
}
function* SubmissionsSaga() {
  yield takeEvery("SubmissionsSlice/getEMCSubmissions", getEMCSubmissionsAPI)
  yield takeEvery("SubmissionsSlice/getSubmissions", getSubmissionsAPI)
}
export default SubmissionsSaga
