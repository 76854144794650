import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Graph from "./chart"
import Intensity from "./intensity"
import Proportions from "./proportions"
//i18n
import { withTranslation } from "react-i18next"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard | myJournal"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col>
              <div>
                <b>Frequency of Emotions</b>
              </div>
              <Graph></Graph>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <b>Intensity of Emotions</b>
              </div>
              <Intensity></Intensity>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <b>Your Pie of Emotions</b>
              </div>
              <Proportions></Proportions>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
