const evaluate = values => {
  var total = 0
  for (var category in values) {
    for (var qid in values[category]) {
      total += parseInt(values[category][qid], 10)
    }
  }
  return total
}
export default evaluate
