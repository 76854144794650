import PropTypes from "prop-types"
import React from "react"

import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { postSubmission } from "store/firestore/questions/slice"
import { updateUserSettings } from "store/firestore/settings/slice"
import s from "./style.module.css"
import { useMemo } from "react"
import { getSubmissions } from "store/firestore/submissions/slice"
import { useState, useEffect } from "react"
import { Formik, Field, ErrorMessage } from "formik"
import { Label } from "reactstrap"
import { updateLsd } from "store/firestore/settings/slice"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Modal,
  FormGroup,
  Input,
  FormCheck,
} from "react-bootstrap"

import * as Yup from "yup"
import { SassArgumentList } from "sass"
import evaluate from "../Evaluators/weigthed"
import Info from "components/Common/Info"

const MyValueWall = props => {
  //meta title
  document.title = "MyDharma | My Value Wall"

  const assessment = "my_value_wall"

  const ques = useSelector(state => {
    return state.Questions.assessments[assessment]
  })
  const categories = useSelector(state => {
    return state.Questions.assessments["my_value_wall"]?.categories
  })

  const questions = useMemo(() => {
    if (!ques) return []
    const map = []
    categories.forEach(cat => {
      Object.values(ques.questions[cat]).forEach(q => {
        map.push({
          ...q,
          category: cat,
        })
      })
    })
    return map
  }, [ques])

  const [settingsId, setSettingsId] = useState(null)
  const [optedValues, setOptedValues] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [formData, setFormData] = useState({}) // Store final selected answers
  const [showModal, setShowModal] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [infoTitle, setInfoTitle] = useState([])
  const [infoMsg, setInfoMsg] = useState([])
  const [modelMsg, setModelMsg] = useState([])
  const [cellAction, setCellAction] = useState([])
  const initialValues = { answer: {} } // Store answers for each question
  const validationSchema = Yup.object().shape(values => {
    // Dynamically generate validation schema based on optedValues
    const schema = {}
    Object.keys(values.answer).forEach(category => {
      schema[category] = Yup.string().required(
        `Answer required for ${category}`
      )
    })
    return schema
  })

  const handleCellClick = category => {
    setSelectedCategory(category)
    if (!optedValues.includes(category)) {
      setCellAction("SELECTION")
      setModelMsg("Do you like to start practicing " + category)
      setShowModal(true)
    } else {
      setCellAction("UNSELECTION")
      setModelMsg("Do you like to stop practicing " + category)
      setShowModal(true)
    }
  }

  // const handleModelOk = () => {
  //   if ("SELECTION" == cellAction) {
  //     if (optedValues.length < 2) {
  //       setOptedValues([...optedValues, selectedCategory])
  //     } else {
  //       setShowModal(false)
  //       setCellAction("INFO")
  //       setInfoMsg(
  //         "Great, you are already practicing enough values, adding this would cause inconsistency"
  //       )
  //       setShowInfo(true)
  //     }
  //   } else if ("UNSELECTION" == cellAction) {
  //     removeOptedCategoryFromArray(optedValues, selectedCategory)
  //   }
  //   if ("INFO" != cellAction) {
  //     const user = JSON.parse(localStorage.getItem("authUser"))
  //     var user_id = user.email
  //     dispatch(
  //       updateUserSettings({
  //         emailId: user_id,
  //         opted_values: optedValues,
  //         docId: settingsId,
  //       })
  //     )
  //     setShowModal(false)
  //   }
  // }

  // function removeOptedCategoryFromArray(array, stringToRemove) {
  //   const index = array.indexOf(stringToRemove)
  //   if (index !== -1) {
  //     array.splice(index, 1)
  //   }
  //   return array
  // }

  function removeOptedCategoryFromArray(array, stringToRemove) {
    return array.filter(item => item !== stringToRemove)
  }

  const handleModelOk = () => {
    if ("SELECTION" === cellAction) {
      setOptedValues(prevOptedValues => {
        const updatedOptedValues =
          optedValues.length < 2
            ? [...prevOptedValues, selectedCategory]
            : prevOptedValues

        if (updatedOptedValues.length !== prevOptedValues.length) {
          setCellAction(prevCellAction => {
            if (prevCellAction !== "INFO") {
              const user = JSON.parse(localStorage.getItem("authUser"))
              const user_id = user.email
              dispatch(
                updateUserSettings({
                  emailId: user_id,
                  opted_values: updatedOptedValues,
                  docId: settingsId,
                })
              )
              setShowModal(false)
              return "INFO"
            }
            return prevCellAction
          })
        } else {
          setCellAction(prevCellAction => {
            if (prevCellAction !== "INFO") {
              setShowModal(false)
              setInfoMsg(
                "Great, you are already practicing enough values, adding this would cause inconsistency"
              )
              setInfoTitle("Information")
              setShowInfo(true)
              return "INFO"
            }
            return prevCellAction
          })
        }

        return updatedOptedValues
      })
    } else if ("UNSELECTION" === cellAction) {
      setOptedValues(prevOptedValues => {
        const updatedOptedValues = removeOptedCategoryFromArray(
          prevOptedValues,
          selectedCategory
        )

        setCellAction(prevCellAction => {
          if (prevCellAction !== "INFO") {
            const user = JSON.parse(localStorage.getItem("authUser"))
            const user_id = user.email
            dispatch(
              updateUserSettings({
                emailId: user_id,
                opted_values: updatedOptedValues,
                docId: settingsId,
              })
            )
            setShowModal(false)
            return "INFO"
          }
          return prevCellAction
        })

        return updatedOptedValues
      })
    }
  }

  // const handleOptionChange = event => {
  //   setFormData({
  //     ...formData,
  //     [selectedCategory]: event.target.value,
  //   })
  // }

  const handleFormSubmit = event => {
    event.preventDefault()
    setSelectedCategory(null)
    setShowModal(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false)
  const areAllQuestionsAnswered = () => {
    if (!optedValues || optedValues.length === 0) return false // No categories opted, so consider all answered

    const catQuestions = []
    for (const category of optedValues) {
      catQuestions.push(...Object.values(ques.questions[category]))
    }

    return catQuestions.every(question => formData[question.id])
  }
  useEffect(() => {
    setAllQuestionsAnswered(areAllQuestionsAnswered())
  }, [formData, optedValues, ques])
  const renderQuestions = () => {
    if (!optedValues) return null
    const catQuestions = []
    for (var i in optedValues) {
      catQuestions.push(...Object.values(ques.questions[optedValues[i]]))
    }

    return (
      <Form onSubmit={handleSubmit}>
        {catQuestions.map(question => (
          <FormGroup key={question.id}>
            <Form.Label className={s.question}>{question.question}</Form.Label>

            <div className="d-flex">
              {question.options.map(option => (
                <Form.Check
                  key={option.value}
                  type="radio"
                  className={s.option}
                  name={question.id}
                  label={option.label}
                  value={option.value}
                  checked={formData[question.id] == option.value}
                  onChange={event =>
                    setFormData({
                      ...formData,
                      [question.id]: event.target.value,
                    })
                  }
                />
              ))}
            </div>
          </FormGroup>
        ))}
        <Button type="submit" disabled={!allQuestionsAnswered}>
          Submit
        </Button>
      </Form>
    )
  }
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem("authUser"))

  const settings = useSelector(state => {
    return state.Settings
  })
  var currentDate = new Date()
  var monthAgo = new Date()
  monthAgo.setDate(currentDate.getDate() - 30)
  monthAgo.setHours(0, 0, 0, 0)
  monthAgo = monthAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)
  useEffect(() => {
    setOptedValues(settings.Settings?.settings?.opted_values)
    setSettingsId(settings.Settings?.docId)
    dispatch(
      getSubmissions({
        emailId: user.email,
        startDate: monthAgo,
        endDate: currentDate.toISOString().split("T")[0],
        assessmentId: assessment,
      })
    )
  }, [dispatch])
  const submissions = useSelector(state => {
    return state.Submissions.SubmissionsMap
  })

  function getTotalFrequencyByEmotion(jsonArray) {
    const frequencyByEmotion = {}

    jsonArray?.forEach(obj => {
      const chart = obj.responses?.chart || {}

      Object.entries(chart).forEach(([emotion, { frequency }]) => {
        frequencyByEmotion[emotion] =
          (frequencyByEmotion[emotion] || 0) + parseInt(frequency, 10)
      })
    })

    return frequencyByEmotion
  }

  var vwSubmissions = submissions[assessment]
  var valueScore = getTotalFrequencyByEmotion(vwSubmissions)
  const submit = values => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    submission.user_id = user.email
    submission.date = new Date().toISOString()
    submission.responses = values
    //submission.result = evaluate(values)
    dispatch(postSubmission({ submission: submission }))
  }
  const submission = {
    user_id: "",
    date: "",
    responses: "",
    result: "",
    assessment_id: "my_value_wall",
  }
  const prepareLsd = () => {
    var lsd = {
      emailId: user.email,
      submissionDate: new Date().toISOString(),
      assessmentId: assessment,
      docId: settingsId,
    }
    dispatch(updateLsd(lsd))
  }
  // const handleSubmit = (e, values) => {
  //   e.preventDefault()
  //   var response = {}
  //   var onlyQuestions = ques?.questions
  //   for (var i in optedValues) {
  //     response[optedValues[i]] = {}
  //   }
  //   for (var categoryKey in onlyQuestions) {
  //     for (var qid in onlyQuestions[categoryKey]) {
  //       if (!formData[qid]) continue
  //       for (var metricKey in onlyQuestions[categoryKey][qid].metric) {
  //         response[categoryKey][
  //           onlyQuestions[categoryKey][qid].metric[metricKey]
  //         ] = formData[qid]
  //       }
  //     }
  //   }

  //   var submission = {
  //     submission: formData,
  //     chart: response,
  //   }

  //   submit(submission)
  //   setSelectedCategory(null)
  //   setShowModal(false)
  //   dispatch(
  //     getSubmissions({
  //       emailId: user.email,
  //       startDate: monthAgo,
  //       endDate: currentDate.toISOString().split("T")[0],
  //       assessmentId: assessment,
  //     })
  //   )
  //   prepareLsd()
  // }

  const handleSubmit = (e, values) => {
    e.preventDefault()
    const assessment_id = assessment // Assuming you have the assessment ID available
    const suffix = "_lsd"
    const submissionKey = `${assessment_id}${suffix}`
    const lsd = settings.Settings?.settings?.submissions?.[submissionKey]

    if (lsd) {
      const lastSubmissionDate = new Date(lsd)
      const currentDate = new Date()
      const lastSubmissionWeek = getWeekNumber(lastSubmissionDate)
      const currentWeek = getWeekNumber(currentDate)

      if (lastSubmissionWeek === currentWeek) {
        setInfoMsg("You cannot submit this assessment within the same week.")
        setInfoTitle("Alert")
        setShowInfo(true)
        return
      }
    }

    var response = {}
    var onlyQuestions = ques?.questions
    for (var i in optedValues) {
      response[optedValues[i]] = {}
    }

    for (var categoryKey in onlyQuestions) {
      for (var qid in onlyQuestions[categoryKey]) {
        if (!formData[qid]) continue
        for (var metricKey in onlyQuestions[categoryKey][qid].metric) {
          response[categoryKey][
            onlyQuestions[categoryKey][qid].metric[metricKey]
          ] = formData[qid]
        }
      }
    }

    var submission = {
      submission: formData,
      chart: response,
    }

    submit(submission)
    setSelectedCategory(null)
    setShowModal(false)
    dispatch(
      getSubmissions({
        emailId: user.email,
        startDate: monthAgo,
        endDate: currentDate.toISOString().split("T")[0],
        assessmentId: assessment,
      })
    )
    prepareLsd()
    setInfoMsg("Your pratice saved successfully...!")
    setInfoTitle("Success")
    setShowInfo(true)
  }

  const getWeekNumber = date => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Reflections" breadcrumbItem="My Value Wall" />
            <Row>
              <Col>Choose one or two values you would like to practice</Col>
            </Row>
            <Row>
              <Col className={s.title}>Values</Col>
            </Row>

            <div>
              <Row>
                {categories.map((category, index) => (
                  <Col
                    key={category}
                    xs={6} // 2 columns per row on mobile
                    sm={4} // 3 columns per row on small screens
                    md={3} // 4 columns per row on medium screens
                    lg={3} // 5 columns per row on large screens
                    className={`${s.category} ${
                      optedValues.includes(category)
                        ? s.optedCategory
                        : s.unoptedCategory
                    }  ${
                      valueScore[category] && valueScore[category] > 20
                        ? s.achieved
                        : ""
                    }`}
                    onClick={() => handleCellClick(category)}
                  >
                    {category}
                  </Col>
                ))}
              </Row>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(e, values) => handleSubmit(e, values)}
                // onSubmit={() => {
                //   alert("Submitted")
                //   console.log(values)
                // }}
              >
                {({ errors, touched }) => <>{renderQuestions()}</>}
              </Formik>
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{selectedCategory}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelMsg}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button onClick={handleModelOk}>OK</Button>
                </Modal.Footer>
              </Modal>
              <Info
                showInfo={showInfo}
                setShowInfo={setShowInfo}
                infoMsg={infoMsg}
                infoTitle={infoTitle ? infoTitle : "Information"}
              ></Info>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

MyValueWall.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(MyValueWall)
