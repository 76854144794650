import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Questions from "pages/Questions/Questions"
import QuestionBank from "pages/Admin/Config/Questions/QuestionBank"
import MyValueWall from "pages/MyValuaWall/MyValueWall"
import Gratitude from "pages/Gratitude/Gratitude"
import GratitudeDB from "pages/GratitudeDB"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Index9 from "pages/Landing/Index9"
import Index from "pages/Landing/Index"

const authProtectedRoutes = [
  { path: "/myvaluewall", component: <MyValueWall /> },
  { path: "/dashboard", component: <Dashboard /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/emotions",
    exact: true,
    component: <Questions />,
  },
  {
    path: "/questions",
    exact: true,
    component: <QuestionBank />,
  },
  {
    path: "/gratitude",
    exact: true,
    component: <Gratitude />,
  },
  {
    path: "/gratitude-dashboard",
    exact: true,
    component: <GratitudeDB />,
  },
  {
    path: "/landing",
    exact: true,
    component: <Index9 />,
  },
]

const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/index", component: <Index /> },
]

export { authProtectedRoutes, publicRoutes }
