import { createSlice } from "@reduxjs/toolkit"
import { collection, getDocs } from "firebase/firestore"
import { db } from "App"

const initialState = {
  categories: [],
  assessments: {},
  submissionSuccessMsg: "",
  submissionSuccess: false,
  submissionFailed: false,
  submissionFailedMsg: "",
}
export const QuestionsSlice = createSlice({
  name: "QuestionsSlice",
  initialState: initialState,
  reducers: {
    getQuestions: (currentState, action) => {},
    postQuestion: (currentState, action) => {},
    storeQuestions: (currentState, action) => {
      currentState.assessments = action.payload.assessments
      currentState.categories = action.payload.categories
    },
    resetQuestions: (currentState, action) => {
      currentState.assessments = initialState.assessments
      currentState.submissionSuccessMsg = ""
      currentState.submissionSuccess = false
      currentState.submissionFailed = false
      currentState.submissionFailedMsg = ""
    },
    postSubmission: (currentState, action) => {},
    sumissionSuccess: (currentState, action) => {
      currentState.submissionSuccessMsg = action.payload
      currentState.submissionSuccess = true
    },
    sumissionFailed: (currentState, action) => {
      currentState.submissionFailedMsg = action.payload
      currentState.submissionFailed = true
    },
  },
})
export default QuestionsSlice.reducer
export const {
  getQuestions,
  postQuestion,
  storeQuestions,
  resetQuestions,
  postSubmission,
  sumissionSuccess,
  sumissionFailed,
} = QuestionsSlice.actions
