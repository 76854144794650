import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import PositivityGraph from "./chart"
import GratitudeBars from "./gratitude"
import ThankfulnessCloud from "./thankfulnesscloud"
import ParentSize from "@visx/responsive/lib/components/ParentSize"
const GratitudeDB = props => {
  //meta title
  document.title = "Dashboard | Gratitude & Positivity"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div>Positivity Graph</div>
              <PositivityGraph></PositivityGraph>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div>Gratitude Bars</div>
              <GratitudeBars></GratitudeBars>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div>Thankfulness Cloud</div>
              <ParentSize>
                {({ width, height }) => (
                  <ThankfulnessCloud width={480} height={480} />
                )}
              </ParentSize>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

GratitudeDB.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(GratitudeDB)
