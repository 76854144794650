import PropTypes from "prop-types"
import React from "react"
import { Container, Alert } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postSubmission } from "store/firestore/questions/slice"
//i18n
import { withTranslation } from "react-i18next"
import EmotionForm from "./EmotionForm"
import evaluate from "../Evaluators/weigthed"
import Info from "components/Common/Info"
import { useState, useEffect } from "react"
import { updateLsd } from "store/firestore/settings/slice"

const Questions = props => {
  //meta title
  document.title = "MyJournal | Emotional Check-In"
  const submission = {
    user_id: "",
    date: "",
    responses: "",
    result: "",
    assessment_id: "emotional_check_in",
  }
  const dispatch = useDispatch()
  const [settingsId, setSettingsId] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [infoMsg, setInfoMsg] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [infoTitle, setInfoTitle] = useState([])
  const user = JSON.parse(localStorage.getItem("authUser"))
  const handleSubmit = values => {
    if (!verifyLsd()) {
      return
    }
    submission.user_id = user.email
    submission.date = new Date().toISOString()
    submission.responses = values
    submission.result = evaluate(values)
    dispatch(postSubmission({ submission: submission }))
    setInfoTitle("Success")
    setInfoMsg("Your emotions checked-in successfully...!")
    setShowInfo(true)
    prepareLsd()
  }
  const assessment = "emotional_check_in"

  const ques = useSelector(state => {
    return state.Questions.assessments[assessment]
  })

  const submissionProperties = createSelector(
    state => state.Questions,
    questions => ({
      submissionSuccess: questions.submissionSuccess,
      submissionSuccessMsg: questions.submissionSuccessMsg,
      submissionFailed: questions.submissionError,
      submissionFailedMsg: questions.submissionErrorMsg,
    })
  )
  const settings = useSelector(state => {
    return state.Settings
  })
  useEffect(() => {
    setSettingsId(settings.Settings?.docId)
    verifyLsd()
  }, [dispatch])
  const verifyLsd = () => {
    const assessment_id = assessment // Assuming you have the assessment ID available
    const suffix = "_lsd"
    const submissionKey = `${assessment_id}${suffix}`
    const lsd = settings.Settings?.settings?.submissions?.[submissionKey]

    if (lsd) {
      const lastSubmissionDate = new Date(lsd)
      const currentDate = new Date()
      const isSameDay =
        lastSubmissionDate.getFullYear() === currentDate.getFullYear() &&
        lastSubmissionDate.getMonth() === currentDate.getMonth() &&
        lastSubmissionDate.getDate() === currentDate.getDate()
      if (isSameDay) {
        setInfoMsg("You have already checked in your emotions for today")
        setInfoTitle("Alert")
        setShowAlert(true)
        setShowInfo(true)
        return false
      }
    }
    return true
  }
  const getWeekNumber = date => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
  }
  const prepareLsd = () => {
    var lsd = {
      emailId: user.email,
      submissionDate: new Date().toISOString(),
      assessmentId: assessment,
      docId: settingsId,
    }
    dispatch(updateLsd(lsd))
  }
  const {
    submissionSuccess,
    submissionSuccessMsg,
    submissionFailed,
    submissionFailedMsg,
  } = useSelector(submissionProperties)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Reflection Hub"
            breadcrumbItem="Daily Emotional Check-In"
          />
          <Info
            showInfo={showInfo}
            setShowInfo={setShowInfo}
            infoMsg={infoMsg}
            infoTitle={infoTitle}
          ></Info>
          {showAlert ? (
            <Alert color="danger" style={{ marginTop: "13px" }}>
              {infoMsg}
            </Alert>
          ) : (
            <EmotionForm questions={ques.questions} submit={handleSubmit} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Questions.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Questions)
