import React, { useState, useEffect } from 'react'
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// Custom Hook
import useScroll from '../components/useScroll'
import useActiveLink from '../components/useActiveLink'

// Image
import logoLight from "../assets/images/logo-light.png"
import logoDark from "../assets/images/logo-dark.png"
import logo from "../assets/images/logo.png"
import lightTxt from "../assets/images/md_light_text.png"
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import "./style.module.css"

const Navbar8 = (props:any) => {

    // scroll page state call
    const navClass = useScroll();

    // activation
    const { activeLink, handleLinkClick } = useActiveLink('.navbar-nav li.active');

    // modal
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          setIsLoggedIn(!!user);
        });
    
        return unsubscribe; // Unsubscribe from the authentication state listener on component unmount
      }, []);
    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
          .then(() => {
            // Sign-out successful
            console.log('User signed out successfully');
          })
          .catch((error) => {
            // An error happened
            console.error('Error signing out:', error);
          });
      };

    return (
        <React.Fragment>
            <nav style={{backgroundColor:'#010d3c', padding:'5px'}} className={"navbar-expand-lg fixed-top tarnsperant-sticky " + navClass} id="navbar">
                <Container>
                    <Row>
                        <Col xs={10} lg={10} sm={10} md={10} className="p-0">                                
                                <img src={logo} alt="" height="42" />
                                <img src={lightTxt} alt="MyDharma's WIN" height= '20rem'/>
                        </Col>   
                        <Col xs={2} lg={2} sm={2} md={2} className="p-0" style={{textAlign:'right'}}>                           
                            <ul className="navbar-nav ms-auto navbar-center">                            
                                {isLoggedIn ? (
                                    <li className={`clr-white nav-item ${activeLink === "/logout" ? "active" : ''}`}>
                                    <Nav.Link style={{ color: 'white' }} href="#" onClick={() => { handleLogout(); handleLinkClick('/logout') }}>
                                        Logout
                                    </Nav.Link>
                                    </li>
                                ) : (
                                    <li className={`clr-white nav-item ${activeLink === "/login" ? "active" : ''}`}>
                                    <Nav.Link style={{ color: 'white' }} href="#" onClick={() => { props.openModel(); handleLinkClick('/login') }}>
                                        Login
                                    </Nav.Link>
                                    </li>
                                )}
                            </ul>                            
                        </Col>                 
                    </Row>
                </Container>
            </nav>
        </React.Fragment>
    )
}

export default Navbar8