import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  error: "",
  success: "",
}

const profileSlice = createSlice({
  name: "profileSlice",
  initialState: initialState,
  reducers: {
    editProfile: (currentState, action) => {},
    profileSuccess: (currentState, action) => {
      currentState.success = "Profile updated sucessfully"
    },
    profileError: (currentState, action) => {
      currentState.error = action.payload
    },
    resetProfileFlag: (currentState, action) => {
      currentState.success = null
    },
  },
})

export const { editProfile, profileSuccess, profileError, resetProfileFlag } =
  profileSlice.actions
export default profileSlice.reducer
