import { createSlice } from "@reduxjs/toolkit"
const initialState = {}
export const forgetPwdSlice = createSlice({
  name: "forgetPwdSlice",
  initialState: initialState,
  reducers: {
    forgetPassword: (currentState, action) => {
      currentState.forgetSuccessMsg = null
      currentState.forgetError = null
    },
    forgetPasswordSuccess: (currentState, action) => {
      currentState.forgetSuccessMsg = action.payload
    },
    forgetPasswordError: (currentState, action) => {},
    serialize: {
      options: {
        function: false,
      },
    },
  },
})

export const { forgetPassword, forgetPasswordSuccess, forgetPasswordError } =
  forgetPwdSlice.actions
export default forgetPwdSlice.reducer
