import React, { useEffect } from "react"
import * as echarts from "echarts/core"
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components"
import { PieChart } from "echarts/charts"
import { LabelLayout } from "echarts/features"
import { CanvasRenderer } from "echarts/renderers"
import { useDispatch, useSelector } from "react-redux"
import ReactEChartsCore from "echarts-for-react"
import { getEMCSubmissions } from "store/firestore/submissions/slice"
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
])

const Proportions = props => {
  const datasetWithFilters = []
  const seriesList = []
  const submissions = useSelector(state => {
    return state.Submissions.Submissions
  })

  const categories = useSelector(state => {
    return state.Questions.assessments["emotional_check_in"]?.categories
  })
  const emotions = categories
  const getProportionsArray = (categories, submissions, noOfDays) => {
    const totalsMap = new Map()
    categories?.forEach(emotion => totalsMap.set(emotion, 0))
    const dates = getDates(noOfDays)
    dates?.forEach(date => {
      categories?.forEach(emotion => {
        const submission = submissions.find(s => {
          const submissionDate = new Date(formatDate(s.date))
          const dateOnly = new Date(date)
          return submissionDate.getTime() === dateOnly.getTime()
        })

        const { frequency = 0, intensity = 0 } =
          submission?.responses?.chart?.[emotion] || {}

        const prevTotal = totalsMap.get(emotion)
        totalsMap.set(emotion, prevTotal + frequency * intensity)
      })
    })
    var percentageMaps = convertToPercentages(totalsMap)
    return [...percentageMaps].map(([name, value]) => ({
      name,
      value,
    }))
  }

  function convertToPercentages(map) {
    const total = Array.from(map.values()).reduce(
      (sum, value) => sum + value,
      0
    )
    const percentageMap = new Map()
    for (const [key, value] of map.entries()) {
      const percentage = (value / total) * 100
      percentageMap.set(key, percentage.toFixed(2))
    }
    return percentageMap
  }

  const getDates = days => {
    const dates = []
    const today = new Date()
    for (let i = 0; i < days; i++) {
      dates.unshift(formatDate(new Date(today).setDate(today.getDate() - i)))
    }
    return dates
  }

  const formatDate = date => {
    const d = new Date(date)
    return d.toISOString().slice(0, 10)
  }

  const getDaysAgo = isoString => {
    const submissionDate = new Date(isoString)
    const today = new Date().setHours(0, 0, 0, 0)
    const diff = Math.ceil((today - submissionDate) / (1000 * 3600 * 24))
    return diff == 0 ? "D" : "D-" + diff
  }

  const data = getProportionsArray(categories, submissions, 7)

  const dispatch = useDispatch()
  var currentDate = new Date()
  var sevenDaysAgo = new Date()
  sevenDaysAgo.setDate(currentDate.getDate() - 7)
  sevenDaysAgo.setHours(0, 0, 0, 0)
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0]
  currentDate.setDate(currentDate.getDate() + 1)
  const user = JSON.parse(localStorage.getItem("authUser"))
  var user_id = user.email
  useEffect(() => {
    dispatch(
      getEMCSubmissions({
        emailId: user_id,
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
      })
    )
  }, [dispatch])

  const options = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "left",
      orient: "vertical",
    },
    series: [
      {
        name: "Proportion",
        animation: "auto",
        animationDuration: 5000,
        animationDurationUpdate: 5000,
        legendHoverLink: true,
        type: "pie",
        radius: ["90%"],
        itemStyle: {
          borderRadius: 5,
          borderColor: "#fff",
          borderWidth: 1,
        },
        // label: {
        //   show: true,
        //   position: "left",
        // },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          show: true,
          length: 15,
          smooth: false,
          minTurnAngle: 90,
          maxSurfaceAngle: 90,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        data: data,
      },
    ],
  }

  return <ReactEChartsCore echarts={echarts} option={options} />
}
export default Proportions
