import React, { useState } from 'react'
import { Container, Row, Col, Modal, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import feature1 from "../../assets/images/emc.png"
import feature2 from "../../assets/images/gratitude.png"
import feature6 from "../../assets/images/valuewall.png"


import screen15 from "../../assets/images/screenshots/screenshot-15.png"
import screen16 from "../../assets/images/screenshots/screenshot-16.png"
import screen17 from "../../assets/images/screenshots/screenshot-17.png"
import screen18 from "../../assets/images/screenshots/screenshot-18.png"
import screen19 from "../../assets/images/screenshots/screenshot-19.png"
import screen20 from "../../assets/images/screenshots/screenshot-20.png"

import slider1 from "../../assets/images/slider1.png"
import slider2 from "../../assets/images/slider2.png"
import slider3 from "../../assets/images/slider3.png"

import user1 from "../../assets/images/users/user-1.jpg"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./style.module.css"

interface SoftwareAboutData {
  icon: string; title: string; text: string;
}

interface Testimonials {
  id: number; author: string; role: string; image: string;
}

const softwareAboutData: SoftwareAboutData[] = [
  { icon: 'pe-7s-light', title: 'Emotional Check-In', text:`Connect with your emotional landscape on a daily basis and develop a deeper awareness of your emotional state.`},
  { icon: 'pe-7s-portfolio', title: 'Attitude of Gratitude', text:`Practice positivity, appreciate life's blessings through gratitude reflections, cultivating joy and fulfillment.` },
  { icon: 'pe-7s-like2', title: 'Wall of Values', text:`Identify core values, embody them daily for purposeful, authentic living aligned with principles.` }
];

const testimonials: Testimonials[] = [
  { id: 1, author: 'Dennis Hammer', role: 'Founder', image: user1 },
  { id: 2, author: 'Robert Chea', role: 'Designer', image: user2 },
  { id: 3, author: 'Victor Smith', role: 'Manager', image: user3 }
];


const Section = () => {

  // modal
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  return (
    <React.Fragment>    
      <section className="softwere-home" id="home">
        {/* <div className="bg-overlay-1"></div> */}
        <div className="home-center">
          <div className="home-desc-center">
            <Container fluid style={{padding:'0px'}}>
              <Row className="align-items-center">
                <Col lg={12} sm={12} className='p-0'>
                  <div className="softwere-home-content text-white mt-5">                    
                    <div>
                      <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                      >
                          <SwiperSlide>
                          <img src={slider2} className="img-fluid" alt="" style={{
                                                                                width: '100%',
                                                                                height: 'auto',
                                                                                objectFit: 'cover',
                                                                              }}/>
                          </SwiperSlide>
                          <SwiperSlide>                            
                              <img src={slider1} className="img-fluid" alt="" style={{
                                                                                width: '100%',
                                                                                height: 'auto',
                                                                                objectFit: 'cover',
                                                                              }}/>                            
                          </SwiperSlide>
                          
                          <SwiperSlide>
                          <img src={slider3} className="img-fluid" alt="" style={{
                                                                                width: '100%',
                                                                                height: 'auto',
                                                                                objectFit: 'cover',
                                                                              }}/>
                          </SwiperSlide>                          
                      </Swiper>
                    </div>                    
                  </div>
                </Col>                
              </Row>
            </Container>           
          </div>
        </div>        
      </section>

      {/* About */}
      <section className="section software-about" id="about">      
        <Container>        
          <Row className='justify-content-center'>
            <div className="bg-overlay-2"></div>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase"><b>W<u>IN</u> Modules</b></h2>
            </div>            
            {(softwareAboutData || [])?.map((item: SoftwareAboutData, index: number) => (
              <Col lg={4} key={index}>
                <div className="softwere-about-box text-center p-3">
                  <div className="softwere-about-icon">
                    <i className={item.icon} />
                  </div>
                  <h4 className="f-15 letter-spacing_2 text-uppercase mt-4 text-primary"><b>{item.title}</b></h4>
                  <p className="text-muted" style={{textAlign:'justify'}}>{item.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="section software-about" style={{padding:'20px'}}>      
        <Container>        
          <Row className='justify-content-center'>
            <div className="bg-overlay-1"></div>
            <div className="text-center">
              <h2 className="heading-title-title letter-spacing_4 text-uppercase" style={{color:'white'}}><b>What is W<u>IN</u>?</b></h2>
            </div>            
            <div style={{textAlign:'justify', color:'#c8edf9'}} >
              <p>Step aboard this voyage of reflection and self-exploration, where the uncharted waters within you are ready to reveal the depths of your true essence. Embark on a journey of self-discovery, where the map is your heart, and the compass is your curiosity. As you sail through the waves of introspection, you'll unveil the hidden treasures of your identity, and with each revelation, you'll chart a course towards a more authentic and empowered you.</p>
              <p>This digital tool is your companion on the path towards a more fulfilling and empowered life. Within these web pages, you'll embark on a remarkable adventure of exploring your emotions, reflecting on your experiences, cultivating gratitude, and aligning your actions with your core values.</p> 
              <p>This first-of-its kind web application offers an innovative dashboard which becomes a mirror to your inner world, reflecting the hues of your emotions and the contours of your growth, while providing a crystal-clear view of your progress through visually revealing graphs. It empowers you to chart your values-driven path with precision, offering the remarkable ability to witness the tangible results of your dedication, inspiring you to reach new heights of self-discovery, authenticity, and empowerment. By engaging with each of the below four modules of this growth tool, you're taking a powerful step towards becoming the best version of yourself.</p>
            </div>
          </Row>
        </Container>
      </section>

      {/* Features */}
      <section className="section" id="features" style={{margin:'20px 0px', padding:'20px 0px'}} >
        <Container>
          <Row className='justify-content-center'>
            <Col lg={10}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase" style={{color:'#e5aca2'}}><b>insights</b></h2>                
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <div className="softwere-features-img mt-4" style={{textAlign:'center', position:'relative'}}>
                <img src={feature1} height={250}/>
              </div>
            </Col>
            <Col lg={6}>
              <div className="softwere-features-content">                
                <h4 className="mt-4 text-uppercase f-20 letter-spacing_2" style={{color:'#56a9cd'}}><b>Daily Emotional Check-In</b></h4>
                <p className="softwere-features-desc mt-4" style={{color:'#6688b8', textAlign:'justify'}}>
                In this module, you'll connect with your emotional landscape on a daily basis. Through thought-provoking questions and prompts, you'll delve into your feelings, understand your emotions, and gain insight into your emotional well-being. By consistently engaging in this practice, you'll develop a deeper awareness of your emotional state, paving the way for greater emotional balance and self-awareness.
                </p>
                <div className="mt-4">
                  <Link to="/emotions" className="btn btn-md btn-dark-custom" style={{color:'#56a9cd'}}>
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-5 align-items-center">
            <Col lg={6}>
              <div className="softwere-features-content">                
                <h4 className="mt-4 text-uppercase f-20 letter-spacing_2" style={{color:'#ee8953'}}><b>Gratitude and Positivity</b></h4>
                <p className="softwere-features-desc mt-4" style={{color:'#f7ab41', textAlign:'justify'}}>
                  Cultivating gratitude is a powerful practice that can transform your outlook on life. In this section, you'll express gratitude for the blessings, experiences, and moments that enrich your hours, days and weeks. By focusing on positivity and appreciation, you'll nurture a mindset of abundance and joy. Through heartfelt reflections and expressions of gratitude, you'll create a reservoir of positivity that infuses your life with meaning and fulfilment.
                </p>

                <div className="mt-4">
                  <Link to="/gratitude" className="btn btn-md btn-dark-custom" style={{color:'#ee8953'}}>
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="softwere-features-img mt-4" style={{textAlign:'center', position:'relative'}}>
                <img src={feature2} height={250} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="mt-5 pt-3 align-items-center">
            <Col lg={6}>
              <div className="softwere-features-img mt-4" style={{textAlign:'center', position:'relative'}}>
                <img src={feature6} height={300} alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="softwere-features-content">               
                <h4 className="mt-4 text-uppercase f-20 letter-spacing_2" style={{color:'#153138'}}><b>Wall of Values</b></h4>
                <p className="softwere-features-desc mt-4" style={{color:'#72949d', textAlign:'justify'}}>
                  Your core values are the compass that guides your actions and decisions. In this section, you'll explore and practice aligning your life with your deeply held values. By consciously embodying these values in your daily choices, you'll experience a sense of purpose and authenticity. As you progress, you'll witness the transformational impact of living in harmony with your values, leading to a life that is rich in integrity, fulfilment, and genuine connection.
                </p>
                <div className="mt-4">
                  <Link to="/myvaluewall" className="btn btn-md btn-dark-custom" style={{color:'#153138'}}>
                    Learn more
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Screenshot */}
      <section className="section" id="screen">
        <Container>
          <Row className='justify-content-center'>
          <div className="bg-overlay-1"></div>
            <Col lg={8}>            
              <div className="text-center">
                <h4 className="heading-title-title letter-spacing_4" style={{color:'white'}}>
                    <b>
                      uddhared ātmanātmānaṁ nātmānam avasādayet <br/>
                      ātmaiva hyātmano bandhur ātmaiva ripur ātmanaḥ
                    </b><br/>
                    <span style={{fontSize:'14px', color:'orange'}}>(Elevate yourself through the power of your mind, and not degrade yourself, for the mind can be the friend and also the enemy of the self.) <i style={{fontFamily:'sans-serif'}}>-- Gita 6.5</i></span>

                </h4>                
              </div>
            </Col>
          </Row>

          
        </Container>
      </section>
    </React.Fragment>
  )

}

export default Section