import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import { Button, Container, Row, Col } from "react-bootstrap"
import firebase from "firebase/app"
import "firebase/database"
import s from "./style.module.css"

const QuestionForm = ({ questions, submit }) => {
  const [currentCategory, setCurrentCategory] = useState(0)
  const totalCategories = Object.keys(questions).length
  const [formValues, setFormValues] = useState({
    responses: {},
  })

  const handleSubmit = (values, { resetForm, setSubmitting, event }) => {
    var response = {}
    for (var categoryKey in questions) {
      response[categoryKey] = {}
      for (var qid in questions[categoryKey]) {
        for (var metricKey in questions[categoryKey][qid].metric) {
          response[categoryKey][questions[categoryKey][qid].metric[metricKey]] =
            values["responses"][categoryKey][qid]
        }
      }
    }

    var submission = {
      submission: values.responses,
      chart: response,
    }

    submit(submission)
  }

  const handleNext = event => {
    if (currentCategory < totalCategories - 1) {
      setCurrentCategory(currentCategory + 1)
      event.preventDefault()
    } else {
      handleSubmit(formValues)
    }
  }

  const handleBack = () => {
    if (currentCategory > 0) {
      setCurrentCategory(currentCategory - 1)
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues(prevValues => ({
      responses: {
        ...prevValues.responses,
        [name]: value,
      },
    }))
  }
  return (
    <Container>
      <Formik initialValues={formValues} onSubmit={handleSubmit}>
        {({ values, handleChange, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {Object.keys(questions).map((category, index) => (
                <div
                  key={category}
                  style={{
                    display: index === currentCategory ? "block" : "none",
                  }}
                >
                  <h3 className={s.category}>{category}</h3>
                  {Object.keys(questions[category]).map(questionId => {
                    const question = questions[category][questionId]
                    const fieldName = `responses.${category}.${questionId}`
                    return (
                      <div key={questionId}>
                        <div className={s.question}>{question.question}</div>
                        <Row>
                          {question.options.map((option, optionIndex) => (
                            <Col key={optionIndex} xs={12} sm={6} md={3}>
                              <label className={s.option}>
                                <Field
                                  type="radio"
                                  name={fieldName}
                                  value={option.value}
                                  checked={
                                    values.responses?.[category]?.[
                                      questionId
                                    ] == option.value
                                  }
                                  onChange={handleChange}
                                />
                                <span>{option.label}</span>
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )
                  })}
                </div>
              ))}
              <Row>
                <Col>
                  <Button
                    type="button"
                    onClick={handleBack}
                    disabled={currentCategory === 0}
                  >
                    Back
                  </Button>
                </Col>
                <Col>
                  {currentCategory < totalCategories - 1 ? (
                    <Button type="button" onClick={handleNext}>
                      Next
                    </Button>
                  ) : (
                    <Button type="submit">Submit</Button>
                  )}
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default QuestionForm
