import { configureStore, compose } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"

import rootReducer from "./reducers"
import rootSaga from "./sagas"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const getDefaultMiddleware = defaultMW => {
  return defaultMW({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      ignoredActionPaths: ["payload.history"],
    },
  }).concat(sagaMiddleware)
}

const persistConfig = {
  key: rootReducer,
  version: 1,
  storage,
  whitelist: ["Questions", "Settings"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware,
})

export const persistor = persistStore(store, null, () => {
  persistor.purge()
})
sagaMiddleware.run(rootSaga)
export default store
