import React, { useEffect } from "react"
//import ReactEChartsCore from "echarts-for-react/lib/core"
import { useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { getSubmissions } from "../../store/firestore/submissions/slice"

import { Text } from "@visx/text"
import { scaleLog } from "@visx/scale"
import Wordcloud from "@visx/wordcloud/lib/Wordcloud"
// import { totoAfricaLyrics } from "./text.fixture"
import s from "./style.module.css"
interface ExampleProps {
  width: number;
  height: number;
  showControls?: boolean;
}

export interface WordData {
  text: string;
  value: number;
}

const colors = ["#143059", "#2F6B9A", "#82a6c2"]

function wordFreq(text: string): WordData[] {
  const words: string[] = text.replace(/\./g, '').split(/\s/);
  const freqMap: Record<string, number> = {};

  for (const w of words) {
    if (!freqMap[w]) freqMap[w] = 0;
    freqMap[w] += 1;
  }
  return Object.keys(freqMap).map((word) => ({ text: word, value: freqMap[word] }));
}

function getRotationDegree() {
  const rand = Math.random();
  const degree = rand > 0.5 ? 60 : -60;
  return rand * degree;
}

type SpiralType = 'archimedean' | 'rectangular';

const ThankfulnessCloud = ({ width, height, showControls }: ExampleProps)  => {
  const [spiralType, setSpiralType] = useState<SpiralType>('archimedean');
  const [withRotation, setWithRotation] = useState(false);
  const daysCount = 7
  const assessment = "gratitude_positivity";
  const dispatch = useDispatch();
  var currentDate = new Date();
  var sevenDaysAgo:any = new Date();
  sevenDaysAgo.setDate(currentDate.getDate() - daysCount);
  sevenDaysAgo.setHours(0, 0, 0, 0);
  sevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  currentDate.setDate(currentDate.getDate() + 1);
  //const user = JSON.parse(localStorage.getItem("authUser"))
  const userString = localStorage.getItem("authUser");
  let user: any = null;
  
  if (userString !== null) {
    user = JSON.parse(userString);
  }
  var user_id = user?.email

  useEffect(() => {
    dispatch(
      getSubmissions({
        emailId: user_id,
        startDate: sevenDaysAgo,
        endDate: currentDate.toISOString().split("T")[0],
        assessmentId: assessment,
      })
    )
  }, [dispatch])

  const submissions = useSelector((state:any) => {
    return state.Submissions.SubmissionsMap
  })

  
  const getThankfulnessText = (submissions:any)=>{
    if (!Array.isArray(submissions[assessment])) {      
      return " ";
    }
    let thankfulnessText = "";
    var submissionsData = submissions[assessment]
    submissionsData.forEach((submission) => {
    const { thankfulness } = submission.responses.submission;
      if (thankfulness) {
        thankfulnessText += `${thankfulness.q27} ${thankfulness.q28} `;
      }
    });
    return thankfulnessText;
  }
  

  const ques = useSelector((state:any) => {
    return state.Questions.assessments[assessment]
  })
  
  const thankfullnessText = getThankfulnessText(submissions);

  const words = wordFreq(thankfullnessText);

  const fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
    range: [10, 100],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);
  
  const fixedValueGenerator = () => 0.5;
  
  if (thankfullnessText) {
    
    return (
      <div className={s.wordcloud}>
      <Wordcloud
        words={words}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font={'Impact'}
        padding={2}
        spiral={spiralType}
        rotate={withRotation ? getRotationDegree : 0}
        random={fixedValueGenerator}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
      {showControls && (
        <div>
          <label>
            Spiral type &nbsp;
            <select
              onChange={(e) => setSpiralType(e.target.value as SpiralType)}
              value={spiralType}
            >
              <option key={'archimedean'} value={'archimedean'}>
                archimedean
              </option>
              <option key={'rectangular'} value={'rectangular'}>
                rectangular
              </option>
            </select>
          </label>
          <label>
            With rotation &nbsp;
            <input
              type="checkbox"
              checked={withRotation}
              onChange={() => setWithRotation(!withRotation)}
            />
          </label>
          <br />
        </div>
      )}  
    </div>
  );    
  } else {
    return <div>Loading....</div>
  }
}
export default ThankfulnessCloud
