import React, { useEffect, useState } from "react"
// import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getQuestions, postQuestion } from "store/firestore/questions/slice"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  Container,
  Form,
  Row,
  Col,
  Badge,
  Button,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap"
import styled from "styled-components"
import { postSubmission } from "store/firestore/questions/slice"
import s from "./style.module.css"
const StyledBadge = styled(Badge)`
  cursor: pointer;
`
const Gratitude = () => {
  document.title = "MyDharma | Gratitude"
  const assessment = "gratitude_positivity"
  const dispatch = useDispatch()
  const ques = useSelector(state => {
    return state.Questions.assessments[assessment]
  })

  const [positivity, setPositivity] = useState("")
  const [gratitude, setGratitude] = useState([])
  const [thankfulness, setThankfulness] = useState("")
  const [gratefulness, setGratefulness] = useState("")
  const submission = {
    user_id: "",
    date: "",
    responses: "",
    result: "",
    assessment_id: "gratitude_positivity",
  }
  const submit = values => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    submission.user_id = user.email
    submission.date = new Date().toISOString()
    submission.responses = values
    //submission.result = evaluate(values)
    dispatch(postSubmission({ submission: submission }))
  }
  const handleSubmit = e => {
    e.preventDefault()
    const answers = {
      positivity: {
        q25: positivity,
      },
      gratitude: {
        q26: gratitude,
      },
      thankfulness: {
        q27: thankfulness,
        q28: gratefulness,
      },
    }
    var submission = {
      submission: answers,
      chart: null,
    }
    submit(submission)
    resetForm()
    console.log(submission)
  }
  const resetForm = () => {
    setPositivity("")
    setGratitude([])
    setThankfulness("")
    setGratefulness("")
  }
  const handlePositivityChange = value => {
    setPositivity(value)
  }

  const handleGratitudeChange = e => {
    const value = parseInt(e.target.value, 10)
    if (e.target.checked) {
      setGratitude([...gratitude, value])
    } else {
      setGratitude(gratitude.filter(val => val !== value))
    }
  }

  const handleThankfulnessChange = e => {
    setThankfulness(e.target.value)
  }
  const handleGratefulnessChange = e => {
    setGratefulness(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Reflection Hub"
            breadcrumbItem="Gratitude & Positivity"
          />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className={s.question}>
                    {ques.questions.positivity.q25.question}
                  </Form.Label>
                  <div>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
                      <StyledBadge
                        key={value}
                        pill
                        bg={
                          value <= parseInt(positivity, 10)
                            ? "success"
                            : "secondary"
                        }
                        className="me-2 py-2 px-3 fs-6"
                        onClick={() => handlePositivityChange(value)}
                      >
                        {value}
                      </StyledBadge>
                    ))}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className={s.row}>
              <Form.Group>
                <Form.Label className={s.question}>
                  {ques.questions.gratitude.q26.question}
                </Form.Label>
                <Row>
                  {ques.questions.gratitude.q26.options.map(option => (
                    <Col key={option.id} lg={4} md={6} className="mb-3">
                      <ToggleButton
                        key={option.id}
                        type="checkbox"
                        id={`gratitude-${option.id}`}
                        value={option.value}
                        onChange={handleGratitudeChange}
                        checked={gratitude.includes(option.value)}
                        className={`${s.option} w-100`}
                        variant="outline-success"
                      >
                        {option.label}
                      </ToggleButton>
                    </Col>
                  ))}
                </Row>
              </Form.Group>
            </Row>
            <Row className={s.row}>
              <Col>
                <Form.Group>
                  <Form.Label className={s.question}>
                    {ques.questions.thankfulness.q27.question}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={thankfulness}
                    onChange={handleThankfulnessChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className={s.row}>
              <Col>
                <Form.Group>
                  <Form.Label className={s.question}>
                    {ques.questions.thankfulness.q28.question}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={gratefulness}
                    onChange={handleGratefulnessChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className={s.row}>
              <Col>
                <Button type="submit" className="Primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Gratitude
