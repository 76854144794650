import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
//import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/slice"
import Profile from "./auth/profile/slice"
import Questions from "./firestore/questions/slice"
import Login from "./auth/login/slice"
import Submissions from "./firestore/submissions/slice"
import Settings from "./firestore/settings/slice"
const rootReducer = combineReducers({
  // public
  Layout,
  Account,
  ForgetPassword,
  Profile,
  Questions,
  Login,
  Submissions,
  Settings,
})

export default rootReducer
