import { createSlice } from "@reduxjs/toolkit"
const initialState = {
  error: "",
  loading: false,
  isUserLogout: false,
}
export const loginSlice = createSlice({
  name: "loginSlice",
  initialState: initialState,
  reducers: {
    loginUser: (currentState, action) => {
      currentState.loading = true
    },
    loginSuccess: (currentState, action) => {
      currentState.loading = false
      currentState.isUserLogout = false
    },
    logoutUser: (currentState, action) => {},
    logoutUserSuccess: (currentState, action) => {
      currentState.isUserLogout = true
    },
    apiError: (currentState, action) => {
      currentState.loading = false
      currentState.isUserLogout = false
      currentState.error = action.payload
    },
    socialLogin: (currentState, action) => {},
  },
  serialize: {
    options: {
      function: false,
    },
  },
})

export const {
  loginUser,
  loginSuccess,
  logoutUser,
  logoutUserSuccess,
  apiError,
  socialLogin,
} = loginSlice.actions

export default loginSlice.reducer
