import React from "react"
import { Modal, Button } from "react-bootstrap"

const Info = props => {
  const { showInfo, setShowInfo, infoMsg, infoTitle } = props

  const handleCloseModal = () => {
    setShowInfo(false)
  }

  return (
    <Modal show={showInfo} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{infoTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{infoMsg}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>OK</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Info
